import { createReducer } from '../../store';
import {
  GET_CONFIG_REQUEST,
  GET_CONFIG_RESPONSE,
  GET_CONFIG_FAILURE,
} from './actions-definitions';

const initialState = {
  isLoading: false,
  enrollmentOpen: false,
  faq: [],
  pricing: {
    standardAppointmentPricing: [],
    nurseProcedurePricing: [],
    prescriptionsPricing: [],
    otherPricing: [],
  },
};

export default createReducer(initialState, {
  [GET_CONFIG_REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GET_CONFIG_RESPONSE](state, action) {
    return {
      ...state,
      enrollmentOpen: action.enrollmentOpen,
      pricing: action.pricing,
      faq: action.faq,
      isLoading: false,
    };
  },
  [GET_CONFIG_FAILURE](state) {
    return {
      ...state,
      isLoading: false,
    };
  },
});
