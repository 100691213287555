import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import firebase from 'firebase/compat/app';
import {connect} from "react-redux";
import { Hero } from '../../components';
import FAQAccordion from '../../components/faq-accordion';
import { mapDispatchToProps, mapStateToProps } from "./selector";
import './styles.scss';

function FAQPage({ faq }) {
  useEffect(() => {
    document.title = 'Leamington Medical Centre - FAQ';
    firebase.analytics().logEvent('page_view', {
      firebase_screen: 'FAQ',
    });
  }, []);

  return (
    <div className="page-faq">
      <Hero className="hero-3" maskRight>
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={6} mdOffset={6}>
            <h1>
              <span>Frequently Asked</span>
              <span className="highlight-color">Questions</span>
            </h1>
            <p className="paragraph-1">
              Have a question? We're here to help. Browse through our frequently asked questions below.
            </p>
          </Grid>
        </Grid>
      </Hero>
      <div className="faq-section">
        <Grid className="grid-container" container spacing={2}>
          <Grid xs={12} md={8} mdOffset={2}>
            <FAQAccordion data={faq} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
